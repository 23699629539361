<template>
  <div class="metadata">
    <table class="table">
      <tbody>
        <template v-if="hasAccessControl">
          <tr>
            <td colspan="2" class="group">
              <h4>Access control</h4>
            </td>
          </tr>
          <template v-if="hasOwner">
            <tr v-for="(o, i) in owned_by" :set="(owner = o.split('/'))" :key="i">
              <td class="title summary-title" title="Owned by">
                <span v-if="i == 0">Owned by</span>
              </td>
              <td>
                <code v-if="owner.length > 0" v-b-tooltip.hover :title="owner[0]"
                  >{{ owner[1] }}
                  <b-badge>{{ o.split(':')[1] }}</b-badge>
                </code>
                <code v-else v-b-tooltip.hover>{{ owner[0] }} </code>
              </td>
            </tr>
          </template>
          <template v-if="isShared">
            <tr v-for="(s, i) in shared_with" :set="(shared = s.split('/'))" :key="i">
              <td class="title summary-title" title="Shared with">
                <span v-if="i == 0">Shared with</span>
              </td>
              <td>
                <code v-if="shared.length > 0" v-b-tooltip.hover :title="shared[0]">
                  {{ shared[1] }}
                  <b-badge>{{ s.split(':')[1] }}</b-badge>
                </code>
                <code v-else v-b-tooltip.hover>{{ shared[0] }} </code>
              </td>
            </tr>
          </template>
          <template v-if="hasLabels">
            <tr v-for="(l, i) in labels" :key="i">
              <td class="title summary-title" title="Labels" :rowspan="labels.lenght">
                <span v-if="i == 0">Labels</span>
              </td>
              <td>
                <code :title="l" v-b-tooltip.hover> {{ l }} </code>
              </td>
            </tr>
          </template>
        </template>
        <tr>
          <td class="group" colspan="2">
            <h4>Metadata</h4>
          </td>
        </tr>

        <tr v-if="keywords.length > 0">
          <td class="title">Keywords</td>
          <td v-html="keywords.join(', ')" v-b-tooltip.hover:title="keywords.join(', ')" />
        </tr>
        <tr v-if="collectionLink">
          <td class="title">Collection</td>
          <td>
            <router-link :to="linkToCollection">
              {{ collectionTitle }}
            </router-link>
          </td>
        </tr>
        <tr v-if="license">
          <td class="title">License</td>
          <td v-html="license" />
        </tr>
        <tr v-if="temporalExtentReadable.length > 0">
          <td class="title">Temporal Extent</td>
          <td>{{ temporalExtentReadable }}</td>
        </tr>

        <template v-for="group in propertyList">
          <tr v-if="group.extension" :key="group.extension">
            <td class="group" colspan="2">
              <h4 v-html="group.label" />
            </td>
          </tr>
          <tr v-for="(prop, key) in group.properties" :key="key">
            <td class="title" :title="key" v-html="prop.label" />
            <td v-html="prop.formatted" v-b-tooltip.hover:title="prop.formatted" />
          </tr>
        </template>
        <template v-if="Array.isArray(providers) && providers.length > 0">
          <tr>
            <td colspan="2" class="group">
              <h4>
                <template v-if="providers.length === 1"> Provider </template>
                <template v-if="providers.length !== 1"> Providers </template>
              </h4>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="provider" v-html="providerHtml" />
          </tr>
        </template>
        <template v-if="hasSummary">
          <tr>
            <td colspan="2" class="group summary">
              <h4>Item Summary</h4>
            </td>
          </tr>
          <template v-for="group in summariesList">
            <tr v-if="group.extension" :key="group.extension">
              <td class="group" colspan="2">
                <h4 v-html="group.label" />
              </td>
            </tr>
            <tr v-for="(prop, key) in group.properties" :key="key">
              <td class="title summary-title" :title="key" v-html="prop.label" />
              <td v-html="prop.formatted" />
            </tr>
          </template>
        </template>
        <!-- <template v-if="hasFilters">
                <tr>
                <td colspan="2" class="group summary">
                    <h4>Item Filters</h4>
                </td>
                </tr >
                <template v-for="filter in filters">
                    <tr v-for="(prop, key) in filter" :key="filter.property+key">
                        <td  v-if="key==='property'" class="group" colspan="2" >
                            <h4 v-html="filter.property" />
                        </td>
                         <template v-else >
                            <td class="title summary-title m-0  p-0 pr-1" :title="key" v-html="key" />
                            <td v-if="Array.isArray(prop)" class="m-0  p-0 pl-1" ><small v-html="prop.join(', ')"/></td>
                            <td v-else class="m-0  p-0 pl-1"><small><small v-html="prop"/></small></td>
                         </template>
                    </tr>
                </template>
            </template> -->
      </tbody>
    </table>
  </div>
</template>

<script>
import StacFields from '@radiantearth/stac-fields'
import common from './common'

export default {
  name: 'MetadataSidebar',
  props: {
    properties: {
      type: Object,
      default: () => ({})
    },
    summaries: {
      type: Object,
      default: () => ({})
    },
    keywords: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    collection: {
      // Item-specific
      type: Object,
      default: () => ({})
    },
    collectionLink: {}, // Item-specific
    license: {},
    temporalExtent: {}, // Collection-specific
    providers: {},
    slugify: {},
    owned_by: [],
    shared_with: [],
    labels: [],
  },
  computed: {
    linkToCollection() {
      // if (this.collectionLink.href != null) {
      //   return `/collection/${this.slugify(this.collectionLink.href)}`
      // }

      return null
    },
    collectionTitle() {
      if (this.collection && this.collection.title) {
        return this.collection.title
      }
      return 'Untitled'
    },
    hasSummary() {
      return this.summaries && typeof this.summaries === 'object' && Object.keys(this.summaries).length > 0
    },
    hasFilters() {
      return this.filters && typeof this.filters === 'object' && Object.keys(this.filters).length > 0
    },
    hasAccessControl() {
      return this.hasOwner || this.isShared
    },
    hasOwner() {
      return this.owned_by && this.owned_by.length > 0
    },
    isShared() {
      return this.shared_with && this.shared_with.length > 0
    },
    hasLabels() {
      return this.labels && this.labels.length > 0
    },
    providerHtml() {
      return StacFields.Formatters.formatProviders(this.providers)
    },
    summariesList() {
      // ToDo: Pass full collection json
      return StacFields.formatSummaries({ summaries: this.summaries }, this.ignore, '')
    },
    propertyList() {
      // ToDo: Pass full item json
      // Better edit fields.json but for now we intercept the format and reedit over
      var propertiesList = StacFields.formatItemProperties({ properties: this.properties }, this.ignore, '')
      for (var [_, properties] of Object.entries(propertiesList)) {
        for (var [propertyKey, property] of Object.entries(properties?.properties)) {
          property.label = propertyKey.split(':').at(-1)
          if (propertyKey === 'datetime') {
            property.formatted = property.value
          }
          if (typeof property.value === 'number') {
            property.formatted = common.methods.formatNumber(property.value)
          }

        }
      }
      return propertiesList
    },
    temporalExtentReadable() {
      if (!Array.isArray(this.temporalExtent)) {
        return ''
      }

      let temporalExtent
      if (this.temporalExtent.length > 1) {
        // Remove union temporal extent in favor of more concrete extents
        temporalExtent = this.temporalExtent.slice(1)
      } else {
        temporalExtent = this.temporalExtent
      }
      return temporalExtent.map(this.formatTemporalInterval).join(', ')
    }
  },
  methods: {
    formatTemporalInterval(interval) {
      return [
        interval[0] ? new Date(interval[0]).toLocaleString() : 'beginning of time',
        interval[1] ? new Date(interval[1]).toLocaleString() : 'now'
      ].join(' - ')
    },
    ignore(key) {
      switch (key) {
        case 'eo:bands':
        case 'providers':
          return false
        default:
          return true
      }
    }
  }
}
</script>

<style scoped lang="css">
.summary-title {
  font-weight: bold;
  width: 20%;
}
.table {
  text-overflow: ellipsis;
  overflow: hidden;
}

table {
  table-layout: fixed;
}
td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
<style>
.metadata td.group.summary {
  background-color: #555;
}

.metadata td.group.summary h4 {
  font-weight: bold;
  color: #ddd;
}
.metadata td.title {
  font-weight: bold;
  width: 15%;
  text-align: right;
  vertical-align: top;
}
.metadata ul,
.metadata ol {
  padding-left: 2em;
}
.metadata-object .metadata-object {
  margin-left: 1em;
}
.metadata dl {
  margin: 0;
  margin-left: 1em;
}
.metadata ul > li > dl,
.metadata ol > li > dl {
  margin-left: 0;
}
.metadata dt {
  display: inline;
}
.metadata dt:after {
  content: ': ';
}
.metadata dd {
  display: inline;
}
.metadata dd:after {
  content: '\A';
  white-space: pre;
  line-height: 1px;
}
.metadata dd:last-of-type:after {
  content: '';
  white-space: normal;
}
.metadata .provider .description {
  font-size: 0.9em;
  line-height: 1.5em;
  margin-bottom: 0.5em;
}
</style>
